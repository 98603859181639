<template>
  <div class="news">
    <Header />
    <van-swipe :autoplay="3000" class="travel-swiper">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <img :src="item.url" class="travel-banner" />
      </van-swipe-item>
    </van-swipe>
    <div v-if="containerList.length>0" class="title">
      <div v-for="item in containerList" class="title-box" :key="item.id">
        <div class="title-text" @click="handleClickChangeDetails(item)">{{h5Language=='En'?item.name:item.nameEn}}</div>
        <div class="line" v-if="active==item.id"></div>
      </div>
    </div>
    <div class="main">
      <van-pull-refresh  v-model="isLoading"  style="min-height: calc(100vh - '260px')" success-text="刷新成功" :head-height="80" @refresh="onRefresh">
        <div v-if="artList.length>0">
          <div v-for="item in artList" :key="item.id">
            <div class="main-box">
              <img :src="item.imgUrl" alt="">
              <div class="box-sty" @click="getDetail(item)">
                <div class="sty-title">{{h5Language=='En'?item.title:item.titleEn}}</div>
                <div class="sty-create">{{h5Language=='En'?'发布时间：'+ item.createTime:'R Time：'+item.createTime}}</div>
                <div class="sty-times">{{h5Language=='En'?'浏览量：'+ item.clickTimes:'Page View：'+item.clickTimes}}</div>
              </div>
            </div>
            <img class="line" src="../assets/home/line3@2x.png" alt="">
          </div>
        </div>

        <div class="art-bottom">{{h5Language=='En'?'～ 没有更多数据了 ～':'～ no data ～'}}</div>

      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Header from '../components/header'
import {cloumnTree, getBanner, getArticleChoose, getArticle,addChick} from "../api";
export default {
  name: 'news',
  components: {
    Header,
  },
  data(){
    return{
      list:[],
      containerList:[],
      active:32,
      artList:[],
      isLoading: false,
      pn:1,
      ps:4,
      h5Language:''
    }
  },
  mounted() {
    this.h5Language = localStorage.getItem('h5Language')||'En'
    this.cloumnId = this.$route.query.id||''
    if(this.$route.query.listId){
      this.cloumnId = this.$route.query.listId
    }
    this.active = this.$route.query.anchorId||32
    this.cloumnTree()
    this.getBanner()
    this.getArticleChoose()
  },
  methods:{
    getDetail(item){
      if(item.contentType==0){
        return
      }
      addChick({id:item.id}).then(res=>{
        console.log(res,'我是点赞数')
      })
      // 1 富文本 2超链接
      if(item.contentType==1){
        this.$router.push({path:'details',query:{id:item.id}})
      }else if(item.contentType==2){
        window.location.href=item.hyperlinks
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.pn = 1
        this.getArticleChoose()
        this.isLoading = false;
      }, 1000);
    },
    getArticleChoose() {
      this.artList = []
      getArticle({cloumnId:this.active, parentId: this.cloumnId,pn:this.pn,ps:this.ps}).then(res => {
        if (res.code == 200) {
          this.artList = res.data
        }
      })
    },
    cloumnTree () {
      cloumnTree().then(res => {
        res.data.forEach(e => {
          if (e.id == this.cloumnId) {
            if (e.children && e.children.length > 0) {
              this.containerList = e.children
              console.log(this.containerList, '111111')
            }
          }
        })
      })
    },
    getBanner () {
      getBanner({ cloumnId: this.cloumnId }).then(res => {
        this.list = res.data
        console.log(res, '我是返回的resid')
      })
    },
    handleClickChangeDetails(item){
      this.active = item.id
      this.getArticleChoose()
    }
  }
}
</script>

<style lang="scss" scoped>
.news{
  width: 100%;
  position: relative;
  .travel-swiper{
    height: 150px;
    width: 100%;
    margin-bottom: 54px;
    margin-top: 50px;
  }
  .travel-banner{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .title{
    position: absolute;
    top: 128px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    width: 328px;
    margin: 0 auto;
    .title-box{
      width: 164px;
      height: 44px;
      border: 1px solid rgba(151, 151, 151, 0.5);
      background: #002E73;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .title-text{
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 20px;
      position: relative;
    }
    .line{
      position: absolute;
      bottom: 6px;
      width: 28px;
      height: 2px;
      border-radius: 1px;
      background: #FFFFFF;
    }
  }
  .title .title-box:first-child{
    border-radius: 4px 0px 0px 4px;
  }
  .title .title-box:last-child{
    border-radius: 0px 4px 4px 0px;
  }
  .main{
    padding: 0 24px;
    .main-box{
      display: flex;
      margin-bottom: 20px;
      img{
        width: 115px;
        height: 104px;
        object-fit: cover;
        border-radius: 4px;
      }
      .box-sty{
        margin-left: 14px;
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .sty-title{
          width: 175px;
          height: 51px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0D387A;
          line-height: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .sty-create{
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4B4C76;
          line-height: 14px;
        }
        .sty-times{
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4B4C76;
          line-height: 14px;
        }
      }
    }
    .line{
      width: 100%;
      height: 1px;
      margin-bottom: 20px;
    }
    .art-bottom{
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 24px;
    }
  }

}

</style>
